'use client';

import React, {
  ChangeEvent,
  ComponentPropsWithoutRef,
  ReactNode,
  Ref,
  useId,
  useState,
} from 'react';

import classNames from 'classnames';

import { FormControl, FormControlProps } from '../FormControl/FormControl';

export type FormInputProps = ComponentPropsWithoutRef<'input'> &
  FormControlProps & {
    inputRef?: Ref<HTMLInputElement>;
    onValueChange?: (value: string) => void;
    afterInput?: ReactNode;
    beforeInput?: ReactNode;
    belowInput?: ReactNode;
    value?: string;
  };

export const FormInput = ({
  label,
  afterInput,
  beforeInput,
  isError,
  tooltip,
  inputRef,
  hint,
  className,
  required,
  visuallyRequired,
  maxLength,
  value,
  belowInput,
  onValueChange,
  onChange,
  ...props
}: FormInputProps) => {
  const id = useId();
  const [internalValue, setInternalValue] = useState('');

  const isControlled = value !== undefined;
  const currentValue = isControlled ? value : internalValue;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    onChange?.(event);
    onValueChange?.(newValue);

    if (!isControlled) {
      setInternalValue(newValue);
    }
  };

  const showCounter = typeof maxLength !== 'undefined';

  return (
    <FormControl
      className={className}
      hint={hint}
      label={label}
      htmlFor={id}
      isError={isError}
      visuallyRequired={visuallyRequired || required}
      tooltip={tooltip}
    >
      <div className="flex gap-2">
        {beforeInput}
        <div className="relative flex-1">
          <input
            id={id}
            data-skeleton-target="base"
            ref={inputRef}
            className={classNames('msq-input', {
              'msq-input-error': isError,
              'pr-[4.75rem]': showCounter,
            })}
            onChange={handleChange}
            required={required}
            maxLength={maxLength}
            value={currentValue}
            {...props}
          />

          {showCounter && (
            <div
              className={classNames(
                'min-w-[4.75rem] pointer-events-none pl-1 pr-3 justify-end absolute top-0 bottom-0 right-0',
                'inline-flex items-center text-base-text-quaternary text-body-sm-d justify-end',
              )}
            >
              <span data-skeleton-target="base">
                {currentValue.length}/{maxLength}
              </span>
            </div>
          )}
        </div>
        {afterInput}
      </div>
      {belowInput}
    </FormControl>
  );
};
